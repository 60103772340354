import React, { useContext, useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { getS3LogoUrl } from '../../utils/s3';
import { STATUS_LIVE } from '../../utils/constants';
import { getShareToken, getTSFileFromLink, getTsFromM3U8Link, getUrlDownloadExam } from '../../lib/api'
import { useSnackBar } from '../../contexts/snackbar'
import { getStatusLabel } from '../../utils/functions'
import { LightLabel } from '../login/components/labels/label-access'
import { SecondButton } from '../login/components/buttons'
import Player from '../../components/player'
import Download from '../../assets/baixar.svg'
import DialogComponent from './components/DialogComponent';
import ShareMenu from './components/ShareMenu';
import { useTranslation } from 'react-i18next';
import { tsUrl } from '../../utils/ts-url';
import { useNavigate } from 'react-router-dom';
import FloatButton from './components/FloatButton';
import { getGlobalPhone } from '../../utils/functions';
import { useMixpanel } from 'react-mixpanel-browser';
import { Container, ContainerButton, ContainerDescription, ContainerLabel, ContainerLogo, ContainerVideo, Header, LabelExam, PlayerContainer, SelectLanguageContainer, StyledFloat } from './style';
import { useExam } from '../../contexts/examContext';
import { PatientContext } from '../../contexts';
import { API_URL, MUSIC_URL } from '../../fetcher/constants'
import storage from '../../lib/storage'
import { isMobileDevice, landscape } from '../../utils/mobile-detect';
import {ContainerBackgroundLoading, ContainerCenter, LabelLoading} from '../loading/style';
import {fetchWithTimeout} from '../../fetcher/fetcher-timeout';

const Watch = () => {
    const globalPhone = getGlobalPhone();

    const { showSnackBar } = useSnackBar()
    const [patient, setPatient] = useState(null)
    const [shareLink, setShareLink] = useState({
        exam: '',
        hash: ''
    })
    const [showDialog, setShowDialog] = useState(false);
    const [callSupport, setCallSupport] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [isLandscape, setIsLandscape] = useState(landscape())
    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1023px)' });   
    const [setting, setSetting] = useState({logoUrl: ""});
    const { t, i18n } = useTranslation();
    const [supportLabelError, setSupportLabelError] = useState('');
    const mixpanel = useMixpanel();
    const [exam, setExam] = useExam()
    const { settings } = useContext(PatientContext)
    const mobileDevice = isMobileDevice()
    const navigate = useNavigate()

    useEffect(() => {
      document.title = `V-Baby - Gravação do Ultrassom - ${settings.name}`;

      if(exam === null) {
        toLoading()
      }

      setPatientFromExam()
      requestShareToken()
      getLogoUrl()

      return () => {
        setExam(null)
      }
    }, [])

    const getLogoUrl = async () => {
      const logoUrl = await getS3LogoUrl()
      setSetting({logoUrl})
    }

    const toLoading = () => {
      navigate('/loading');
    };

    const setPatientFromExam = async () => {
      try {

        const handleMp4Url = () => {
          if (mp4Url){
            setPatient({
              intro: introURL ? introURL : "",
              src: mp4Url,
              secondarySrcs: [m3u8],
              type: 'video/mp4',
              date: date,
              examStatus: examStatus,
              showRate: showRate
            })
          } else {
              setPatient({
                intro: '',
                src: '',
                type: 'video/mp4',
                date: date,
                examStatus: examStatus,
                showRate: showRate
              })
              setTimeout(() => {
                setCallSupport(true);
              }, 5000);
          }
        }

        if(!exam) {
          throw new Error('Exam is null')
        }
        
        const { introURL, m3u8, mp4Url, examStatus, date, showRate, examId, cycle } = exam

        var vmusicError, responseM3U8, m3u8Url

        try{
          const response = await fetch(`${API_URL}/patient/media/${examId}/${cycle}/vmusic-token`, {
            headers: {
              'Authorization': `Bearer ${storage.getToken()}`,
            }
          })
          const responseString = await response.text()
          m3u8Url = `${MUSIC_URL}/v1/vlab/m3u8/${examId}/${responseString}/vod.m3u8`
        } catch (e) {
          console.log( e)
          vmusicError = true
          m3u8Url = m3u8
        }
        
        responseM3U8 = await getTsFromM3U8Link(m3u8)

        if(responseM3U8) {
          var responseTS
          if (vmusicError){
            responseTS = await getTSFileFromLink(tsUrl(responseM3U8))
          }

          try {
            const response = await fetch(m3u8Url)
            const responseText = await response.text()
            const urlPattern = /https?:\/\/[^\s"]+/g
            const match = responseText.match(urlPattern)
            await fetchWithTimeout(match[0], 3000)
          } catch(error) {
            console.log(error)
            handleMp4Url()
            return
          }
          
          if((!vmusicError || responseTS === 200) ) {

            setPatient({
              intro: introURL ? introURL : "",
              src: m3u8Url,
              secondarySrcs: [mp4Url, m3u8],
              type: 'application/x-mpegURL',
              date: date,
              examStatus: examStatus,
              showRate: showRate
            })
          } else {
            handleMp4Url();
          }
          
        } else {
          handleMp4Url();
        }
      } catch (error) {
        toLoading()
      }
    }

    const requestShareToken = async () => {
        try {
            const { data } = await getShareToken()

            setShareLink(data)

        } catch (error) {
            console.log(error)
        }
    }

    const showStatusDownloadMP4 = (status) => {
  
      switch (status) {
        case 'enqueued':
          showSnackBar(t('alertMessages.downloadingExam'), 'waiting', t('alertMessages.babyDetails'), null)
          break;
        case 'generating':
          showSnackBar(t('alertMessages.downloadingExam'), 'waiting', t('alertMessages.preparingVideo'), null)
          break;
        case 'ready':
          showSnackBar(t('alertMessages.concluded'), 'success', '')
          break;
        case 'error':
          showSnackBar(t('alertMessages.downloadingExam'), 'waiting', t('alertMessages.error'))      
          setSupportLabelError(t('dialog.errorDownload'));
          setCallSupport(true)
          break;
        case 'no_content':
          showSnackBar(t('alertMessages.downloadingExam'), 'waiting', t('alertMessages.noContent'))
          break;
        default:
          showSnackBar(t('alertMessages.downloadingExam'), 'waiting', t('alertMessages.downloadError'))
      }
    };

    const getDownloadLink = async () => {

      const { hash } = shareLink;
      setIsLoading(true)
      showStatusDownloadMP4("enqueued")
      const intervalId = setInterval(async () => {
        try {      
          const { data } = await getUrlDownloadExam(hash)

          showStatusDownloadMP4(data.status)
    
          if(data.status === 'ready') {
            clearInterval(intervalId)
            window.open(data.url, '_self')
            mixpanel.track('WEBAPP_CLICK_ON_DOWNLOAD', { 
              distinct_id: globalPhone,
              'DOWNLOAD_DONE': true,
              'LINK_STATUS': 'ready',
            });
            setShowDialog(patient.showRate)
            setIsLoading(false)
          }
        } catch(error) {
            mixpanel.track('WEBAPP_CLICK_ON_DOWNLOAD', { 
              distinct_id: globalPhone,
              'DOWNLOAD_DONE': false,
              'LINK_STATUS': 'error',
            });
            handleMessage(t('alertMessages.error'), 'waiting', t('alertMessages.error'))
            setCallSupport(true)
            clearInterval(intervalId)
            setIsLoading(false)
          } 
      }, 3000)

      setTimeout(() => {
        clearInterval(intervalId)
      }, 60000 * 10)
    };

    const handleMessage = (message, type, secondMessage, timeToHide) => {
        showSnackBar(message, type, secondMessage, timeToHide);
    };
     
    const formatDate = (date, language) => {
      const parsedDate = parseISO(date);
      const formatPattern = language === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
      return format(parsedDate, formatPattern);
    };
    
    const formatTime = (date, language) => {
      const parsedDate = parseISO(date);
      const timeFormat = language === 'en-US' ? 'h:mm a' : 'HH:mm';
      return format(parsedDate, timeFormat);
    };

    const verifyOrientation = () => {
        console.log('change orientation')
        setIsLandscape(landscape())
    }

    window.addEventListener("orientationchange", verifyOrientation);

    return (
        patient ? (
          <Container isLandscape={isLandscape} isMobile={isMobile}>

              <ContainerVideo isLandscape={isLandscape} isMobile={isMobile}>
                <Header isLandscape={isLandscape}>
                  <ContainerLogo url={setting.logoUrl}></ContainerLogo>
                  
                  <ContainerDescription isMobile={isMobile} isDesktop={isDesktop} isTablet={isTablet} isLandscape={isLandscape}>
                    <LabelExam>{t('watch.examDay', { date: formatDate(patient.date, i18n.language) })}</LabelExam>
                      
                    <ContainerLabel  isLandscape={isLandscape} isMobile={isMobile}>
                      <LightLabel>{t('watch.realized', { date: formatTime(patient.date, i18n.language) })}</LightLabel>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        {patient.examStatus === STATUS_LIVE && <div style={{ width: '5px', height: '5px', borderRadius: '50%', backgroundColor: 'red' }}></div>}
                        <LightLabel>{getStatusLabel(patient.examStatus, i18n)}</LightLabel>
                      </div>  
                    </ContainerLabel>
                  </ContainerDescription>
                </Header>

                <PlayerContainer isMobile={isMobile} isLandscape={isLandscape}>
                  <Player
                    options={{ 
                      sources: [
                        { src: patient.intro, type: 'video/mp4' },
                        { src: patient.src, type: patient.type }
                      ],
                      secondarySrcs: patient.secondarySrcs,
                    }}
                    onError={()=> setCallSupport(true)}
                    isLandscape={isLandscape}
                    globalPhone={globalPhone}
                  />
                </PlayerContainer>

                <ContainerButton isMobile={isMobile}>
                  <SecondButton className="gtm-btn" id='gtm-btn-download' variant="contained" type="button" onClick={getDownloadLink} disabled={isLoading || callSupport || patient.examStatus === STATUS_LIVE}>
                      <img 
                          style={{
                            padding: '0px 6px 0px 0px',
                            pointerEvents: 'none',
                          }}
                          src={Download} 
                      /> 
                      {t('buttons.download')}
                  </SecondButton>

                  <ShareMenu className="gtm-btn" id="gtm-btn-share" globalPhone={globalPhone} exam={shareLink.exam} hash={shareLink.hash} examType={patient.examStatus} disabled={callSupport} />
                </ContainerButton>

                {showDialog 
                    && (isMobile || isTablet) 
                    && <DialogComponent onShare={shareLink.hash} open={true} type="rating" />
                }
                {callSupport 
                    && <DialogComponent globalPhone={globalPhone} open={true} type="support" supportLabelError={supportLabelError} />                        
                }

                <StyledFloat isLandscape={isLandscape}>
                  <FloatButton supportMessage={callSupport} globalPhone={globalPhone} />
                </StyledFloat> 
              </ContainerVideo>

              <div style={mobileDevice ? { margin: '12px 0'} : { position: 'absolute', bottom: '4px' }}>
                  <SelectLanguageContainer isMobile={isMobile}>
                    <span className='selected-language'>{t("changeLan.label")}</span>
                    <button 
                        className='selected-language-button' 
                        style={i18n.language === 'pt-BR' ? { color: '#0145D5', fontWeight: 600 }: {}}
                        onClick={() => { i18n.changeLanguage('pt-BR') }}
                    >
                        Português
                    </button>
                    |
                    <button 
                        className='selected-language-button' 
                        style={i18n.language === 'en-US' ? { color: '#0145D5', fontWeight: 600 }: {}}
                        onClick={() => { i18n.changeLanguage('en-US') }}
                    >
                        English
                    </button>
                    |
                    <button 
                        className='selected-language-button' 
                        style={i18n.language === 'es' ? { color: '#0145D5', fontWeight: 600 }: {}}
                        onClick={() => { i18n.changeLanguage('es') }}
                    >
                        Español
                    </button>
                  </SelectLanguageContainer>
                  <div style={{marginBottom: '12px', color: '#5E5E5E'}}>
                    <a
                      href="https://documentos.vlabhealth.com/POLITICADEPRIVACIDADE.pdf"
                      rel="noopener noreferrer"
                      className='terms-link' 
                    >
                      {t('terms.privacyPolitics')}
                    </a>
                    |
                    <a
                      href="https://documentos.vlabhealth.com/TermosUsoConvidados.pdf"
                      rel="noopener noreferrer"
                      className='terms-link' 
                    >
                      {t('terms.termsOfUse')}
                    </a>
                    |
                    <a
                      href="https://documentos.vlabhealth.com/TermoDeColetaDeDadosDeSaúde.pdf"
                      rel="noopener noreferrer"
                      className='terms-link' 
                    >
                      {t('terms.healthTerms')}
                    </a>
                  </div>
                </div>
          </Container>
        ) : (
          <ContainerBackgroundLoading>
            <ContainerCenter>
                <LabelLoading style={{color: '#ECF2F8', fontWeight: 800}}>  {t('loadingExam')} </LabelLoading>
            </ContainerCenter>
          </ContainerBackgroundLoading>
        )
    )
}

export default Watch